.auth-content{
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  width: 100%;
  height: 100vh; /* Utiliza el 100% de la altura de la ventana */
  background: url("../../assets/svgs/background.svg") center/cover no-repeat; /*https://loading.io/background/m-wave/ donde se generó*/
}
.auth-form{
  padding: 20px 0;
  width: 350px;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.formContent{
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  width: 100%;
}
.formCustom{
  padding: 20px 0;
  width: 90%;
  height: auto;
}

.formCustomPaper{
  padding: 20px 0;
  width: 100%;
}
