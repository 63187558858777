.chat-content {
  height: 90vh;
  padding: 16px;
}

.chat-main {
  height: 100%;
  overflow: hidden;
}

.chat-items {
  padding-right: 20px;
  height: 87%;
  overflow: hidden;
  overflow-y: auto;
}

.chat-textarea {
  padding: 10px;
  display: flex;
  background-color: #d2d2d2;
}
.chat-textarea textarea{
  height: 50px;
  width: 100%;
  resize: none;
  border: none;
  outline: none;
  padding: 5px;
}

/* comments.css */

.comment-item {
  background-color: #f8f8f8;
  padding: 12px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment-text {
  font-size: 14px;
  color: #333;
}
