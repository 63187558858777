html,
body,
#root,
.may {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.white g,
.white g g path,
.white g g g path,
.white g g g g path,
.white g path{
  fill: #fff;
}

.white{
  color: white;
}

.img-content{
  overflow: hidden;
  width: 100%;
  border: solid 1px #ccc;
}

.img-content.active{
  border: solid 3px #ccc;
}

.img-fluid{
  width: 100%;
  transition: transform .2s; /* Animation */
}

.img-fluid.active{
  transform: scale(1.2);
}

.capitalized-text {
  text-transform: capitalize;
}

.bgMain{
  background-color: #f0f3f6;
  min-height: 100vh;
}

.paper{
  min-height: 70vh;
}
.cursor-pointer{
  cursor: pointer;
}

.uppercase{
  text-transform: uppercase;
}

.title-h3{
  font-weight: bold;
}

.title-h2{
  font-size:24px !important;
}
.title-h4{
  font-size:18px !important;
}
.title-h6{
  font-size:12px !important;
}

.border-bottom{
  border-bottom: 1px solid #ab47bc;
}
