.container-cropper{
    position: relative;
    /*height: 330px;*/
    overflow: hidden;
    border: solid 1px #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.container-cropper img{
    /*width: 100%;*/
}
.container-cropper input{
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}

.container-cropper .flex{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}