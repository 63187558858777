.Upload-content{
  position: relative;
}
.Upload-content input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.crop-container{
  height: 250px;
}
.controls{
  padding: 6px;
  background-color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
